import Vue from "vue";
import Vuex from "vuex";
import api from "./api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth: false,
    user: {
      account_role: null,
      email: "",
      id: 0,
      registration_name: "",
      image: null,
      current_business: 0,
      control_monitoringaccount_limit: 0,
      ads_meta_monitoringaccount_limit: 0,
      data_start_limit: "",
      data_end_limit: "",
    },
    redirect_url: null,
    current_user_type: 0,
    deliverable_filter: [1, 2, 3, 4, 5],
    campaigns_filter: [1, 2, 3, 4],
    business_campaigns_filter: [1, 2, 3, 4],
    business_campaigns_list_order: {
      sort_by: "name",
      order_by: "asc",
    },

    business_list: [
      {
        code: 0,
        label: "Test",
      },
    ],
  },
  mutations: {
    updateUser(state, payload) {
      state.user.account_role = payload.account_role;
      state.user.email = payload.email;
      state.user.id = payload.id;
      state.user.registration_name = payload.registration_name;
      state.user.image = payload.image;
      state.user.current_business = payload.current_business;
      state.user.control_monitoringaccount_limit = payload.control_monitoringaccount_limit;
      state.user.ads_meta_monitoringaccount_limit = payload.ads_meta_monitoringaccount_limit;
      state.user.data_start_limit = payload.data_start_limit;
      state.user.data_end_limit = payload.data_end_limit;
    },
    updateUserCreator(state, payload) {
      state.user.account_role = payload.account_role;
      state.user.email = payload.email;
      state.user.id = payload.id;
      state.user.image = payload.image;
      state.user.current_business = payload.current_business;
    },
    updateCreatorName(state, payload) {
      state.user.registration_name = payload.name;
    },
    makeAuth(state, payload) {
      state.auth = payload;
    },
    set_deliverable_filter(state, payload) {
      state.deliverable_filter = payload;
    },
    set_redirect_route(state, payload) {
      state.redirect_url = payload;
    },
    set_campaigns_filter(state, payload) {
      state.campaigns_filter = payload;
    },
    set_business_campaigns_filter(state, payload) {
      state.business_campaigns_filter = payload;
    },
    setCurrentUserType(state, payload) {
      state.current_user_type = payload;
    },
    setBusiness_campaigns_list_order(state, payload) {
      state.business_campaigns_list_order = payload;
    },
    updateBusinessList(state, payload) {
      state.business_list = payload;
    },
    updateCurrentBusinessID(state, id, control_monitoringaccount_limit) {
      state.user.current_business = id;
      state.user.control_monitoringaccount_limit =
        control_monitoringaccount_limit;
    },
  },
  actions: {
    async getBusinessList(context) {
      let result = await api.getBusinessList();
      result = result.map((item) => {
        return {
          code: item.code,
          label: window.$app.$options.filters.truncate(item.label, 20, "..."),
        };
      });

      context.commit("updateBusinessList", result);
    },
    async getCurrentUser(context) {
      let result = await api.getCurrentUser();
      if (result.account_role === 1) {
        context.commit("updateUser", result);
      } else {
        context.commit("updateUserCreator", result);
        let influencerResult = await api.getCurrentInfluencer();
        context.commit("updateCreatorName", influencerResult);
      }
    },
    setRedirect(context, payload) {
      context.commit("set_redirect_route", payload);
    },
  },
  modules: {},
});
